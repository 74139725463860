import Project from "@/model/Project";

const projectsModule = {
	state: {
		projects: [
			new Project(
				"Deutsche Bahn",
				"MTx & Mosaik",
				"Senior Fullstack Engineer",
				`Das bestandsverfahren MTx soll in einer neuen Generation übergehen (FGMT - Folgegeneration Mobiles Terminal), 
während die bestehende Generation im Wartungsmodus übergeht.
<br>Das Projekt wird deutschlandweit von den Zugbegleitern für die E-Ticket Kontrolle, Fahrpreisnacherhebung, Ticketverkauf sowie Reiseauskunft verwendet.
<br>Hier handelt es sich um ein agiles, skaliertes Android Projekt nach SAFe - komplett geschrieben in Kotlin.
Es wurde Clean Architecture verwendet, um eine horizontal geschnittene Architektur der Module zu ermöglichen.
<br>Auf der UI / Presentation Ebene wurde MVVM eingesetzt.`,
				"03.2019",
				"heute",
				[
					"Kotlin",
					"Java",
					"Docker",
					"JBoss",
					"Spring Boot",
					"Native Android",
					"Dagger 2",
					"RxJava 3",
					"Android Jetpack",
					"LiveData",
					"Room",
					"Clean Architecture",
					"MVVM",
					"Scaled Agile Framework",
					"SQL",
					"Shell Scripting",
					"DataBinding",
					"Secure Access Module",
					"Bixolon SPP-R310",
					"Epson TM-P80",
					"Zebra ZQ510",
					"Zahlungsterminal",
					"GSON",
					"Moshi",
					"Retrofit",
					"Timber",
					"Bluetooth",
					"Jenkins",
					"Maven",
					"Gradle",
					"JUnit",
					"Espresso",
					"Robolectric",
					"Cucumber",
					"PrimeFaces",
					"SQL Delight",
					"Oracle SQL",
					"Jetpack Navigation",
					"Cryptography",
					"XML",
					"JSON",
					"Gradle Multi Module Project",
				],
			),

			new Project(
				"Energieversorgungsunternehmen",
				"Tätigkeitsbericht Applikation",
				"Android Entwickler",
				`Die Mitarbeiter des Unternehmens sollen Aufgabenlisten, Handzeichnungen, Kamera- und Galeriebilder an den Tätigkeitsberichten anheften können.
				<br><br>Eine Echtzeit Synchronisation der Berichte wird mithilfe der Firebase Realtime Database auf mehreren Geräten realisiert, die im Kontext des gleichen Benutzers angemeldet sind (Anmeldung läuft über die Google Konten).
				<br><br>Die Anwendung wurde als einer nativen Android Anwendung mit einer MVVM Architektur entwickelt.`,
				"12.2018",
				"03.2019", // 4 Monate
				[
					"Kotlin",
					"Android",
					"RxJava",
					"Retrofit",
					"Glide",
					"Room",
					"MVVM",
					"LiveData",
					"DataBinding",
					"Kanban",
					"Gradle",
					"Jenkins",
					"Dagger",
					"Crashlytics",
					"Android Navigation",
					"Firebase",
					"Websockets",
				],
			),

			new Project(
				"Logistik Unternehmen",
				"Diebstahlerkennung",
				"Android Entwickler",
				`LKWs die für längere Zeit unbewacht verlassen werden sind immer im Gefahr, dass der Tankdeckel aufgebohrt wird, um Sprit abzuzapfen.
				 <br><br>
				 Die künstliche Intelligenz soll Abhilfe schaffen, indem es die LKW Spiegelkamera Bilder mithilfe der Tensorflow Library analysiert und der Fahrer alarmiert, falls jemand zu lange in der Nähe des LKWs mit einer Bohrmaschine steht.`,
				"10.2018",
				"11.2018", // 2 Monate
				["Android", "Camera2 API", "TensorFlow Lite", "Python"],
			),

			new Project(
				"Logistik Unternehmen",
				"App für einen Geschäftsprozesse System",
				"Android Entwickler",
				`Ein Logistik Unternehmen besitzt einen Web System, welches für Schulungen der LKW-Fahrer verwendet wird. 
				<br><br>Die Speditionsfahrer sollen anstehende Schulungen unterwegs absolvieren und schulungsrelevante Dokumente lesen können.
				Die App wird auf Android OS basierte TomTom Navigationssysteme laufen.`,
				"04.2018",
				"09.2018", // 6 Monate
				["Android", "Retrofit", "Glide", "MVVM", "Dagger", "Firebase", "KSOAP2"],
			),

			new Project(
				"IT-Dienstleister",
				"Zeiterfassung",
				"Android & Java Entwickler",
				`Es wird eine Anwendung für die interne Arbeitszeiterfassung & Auswertung benötigt. 
				Die Java SE RichClient Anwendung soll Excel Spreadsheets für die Abrechnung der Arbeitszeiten generieren.
				<br><br>Die Zeiterfassung soll auch mobil verfügbar sein, damit die Unternehmensmitarbeiter auch vor Ort beim Kunden die Arbeitszeiten erfassen können - diese wurde als einer nativen Android Applikation entwickelt.`,
				"10.2017",
				"03.2018", // 6 Monate
				["Java SE", "Oracle SQL", "Android", "REST", "Apache POI"],
			),

			new Project(
				"Pharmazeutisches Unternehmen",
				"Domänen Migrationsskript",
				"Entwickler",
				"Durch die Übernahme eines anderen pharmazeutischen Unternehmens entstand die Anforderung, alle Daten aus der übernommenen Domäne in die neue Domäne zu migrieren. Es ist eine unverzichtbare Anforderung, dass alle migrierten Dateien aus der alten Domäne nach der Migration byte-identisch sind.",
				"08.2017",
				"08.2017", // 1 Monat
				["VB Script"],
			),

			new Project(
				"IT-Dienstleister",
				"Prozess Diagramm Generator",
				"Backend Entwickler",
				`Eine in Haus entwickelte Web Anwendung für die Prozessdigitalisierung soll erweitert werden, damit die Prozessabläufe einer Matrix in Diagrammen dargestellt werden.<br>Bei sehr komplexen Prozessabläufen mit viele Knoten sollen die generierten Diagramme Abhilfe schaffen.`,
				"06.2017",
				"07.2017", // 2 Monate
				["Adobe Coldfusion", "Java", "JavaScript"],
			),

			new Project(
				"Pharmazeutisches Unternehmen",
				"Online RichText- und Spreadsheet Editor",
				"Backend Entwickler",
				`Microsoft Word- und Excel Dokumente sollen aus einem webbasierten DMS-System verarbeitet werden können. Beim Abspeichern sollen die Dokumente ins DMS zurück revisioniert werden.<br>Die Notwendigkeit dieser Funktionalität entsteht daraus, da keine Software auf den validierten Maschinen installiert werden darf.`,
				"04.2017",
				"05.2017", // 2 Monate
				["Adobe Coldfusion", "Java", "DevExpress", "ASP.NET Core"],
			),

			new Project(
				"IT-Dienstleister",
				"vCard Generator",
				"Backend Entwickler",
				"Die Vertriebsabteilung möchte die Kunden aus dem CRM-System auf den Dienstgeräten bei Bedarf importieren können, ohne die Kunden manuell auf den jeweiligen Geräten anzulegen.",
				"03.2017",
				"03.2017", // 1 Monat
				["Adobe Coldfusion", "Java", "Javascript", "CSS"],
			),

			new Project(
				"IT-Dienstleister",
				"Automatisierte Firewall Backup Sicherung",
				"Backend Entwickler",
				`Kunden des Unternehmens besitzen Firewalls der Firma Fortinet. Im Falle eines Ausfalls muss die Firewall umgehend ersetzt und erneut konfiguriert werden.<br> Dies erfolgt i.d.R. durch externe Dienstleister. Um Kosten zu senken, sollen die Konfigurationen der Firewalls in einstellbaren Zeitintervallen automatisch gesichert werden.`,
				"01.2017",
				"02.2017", // 2 Monate
				["Adobe Coldfusion", "Java", "Shell Script", "SSH", "SFTP"],
			),
		],
	},
};

export default projectsModule;
