export default class Project {
	public company: string;

	public name: string;

	public role: string;

	public description: string;

	public start: string;

	public end: string;

	public technologies: string[];

	constructor(
		company: string,
		name: string,
		role: string,
		description: string,
		start: string,
		end: string,
		technologies: string[],
	) {
		this.company = company;
		this.name = name;
		this.role = role;
		this.description = description;
		this.start = start;
		this.end = end;
		this.technologies = technologies;
	}
}
