import Vue from "vue";
import VueRouter from "vue-router";
import RoutesModule from "../data/routesModule";

Vue.use(VueRouter);

const { routes } = RoutesModule.state;

const router = new VueRouter({ routes });


export default router;
