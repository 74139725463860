
import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class App extends Vue {
  drawer = false;
  titleText = "Radu Märza - Senior Fullstack Engineer";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public routes = this.$store.state.routesModule.routes.filter((v: any) => v.mainNav === true);
  get title() {
    if (this.$vuetify.breakpoint.xs) return this.$route.name;
    return this.titleText;
  }
}
