const Welcome = () => import("../views/Home.vue");
const Uups = () => import("../views/Uups.vue");
const Impressum = () => import("../views/Impressum.vue");
const Projects = () => import("../views/Projects.vue");
const Contact = () => import("../views/Contact.vue");
const About = () => import("../views/About.vue");

const routesModule = {
	state: {
		routes: [
			{
				path: "/",
				name: "Home",
				component: Welcome,
				mainNav: true,
			},
			{
				path: "/projects",
				name: "Projekte",
				component: Projects,
				mainNav: true,
			},
			{
				path: "/contact",
				name: "Kontakt",
				component: Contact,
				mainNav: true,
			},
			{
				path: "/about",
				name: "Über mich",
				component: About,
				mainNav: true,
			},
			{
				path: "/impressum",
				name: "Impressum",
				component: Impressum,
				mainNav: false,
			},
			{
				path: "*",
				name: "Uups",
				component: Uups,
				mainNav: false,
			},
		],
	},
};

export default routesModule;
