export default class Skill {
	public skillName: string;

	public skillLevel: number;

	public learned: string;

	constructor(skillName: string, skillLevel: number, learned = "Beruflich") {
		this.skillName = skillName;
		this.skillLevel = skillLevel;
		this.learned = learned;
	}
}
