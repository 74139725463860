import Vue from "vue";
import Vuex from "vuex";
import SkillsModule from "./skillsModule";
import RoutesModule from "./routesModule";
import ProjectsModule from "./projectsModule";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		skillsModule: SkillsModule,
		projectsModule: ProjectsModule,
		routesModule: RoutesModule,
	},
});
