import Vue from "vue";
import VueParticlesBg from "particles-bg-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./routes";
import store from "./data";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;
Vue.use(VueParticlesBg);

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
}).$mount("#app");
